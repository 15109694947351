import React from 'react';
import Authentication from '../Authentication';
import JoinOurTeam from '../JoinOurTeam';
import { makeStyles } from '@material-ui/core/styles';
import useIsMobileView from '../../hooks/useIsMobileView';

const useStyles = makeStyles(theme => ({
  rightHeader: {
    display: 'flex'
  },
  spacer: {
    border: '1px solid #ddd',
    margin: '0 30px'
  }
}));

const RightHeader = () => {
  const classes = useStyles();
  const isMobileView = useIsMobileView();

  return (
    <div className={classes.rightHeader}>
      <Authentication />
      {!isMobileView && (
        <>
          <Spacer />
          <JoinOurTeam />
        </>
      )}
    </div>
  );
};

const Spacer = () => {
  const classes = useStyles();
  return <div className={classes.spacer}></div>;
};

export default RightHeader;
