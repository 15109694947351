import actionTypes from '../actionTypes';

const initialState = {
  isLoggedIn: false,
  user: {},
  isAuthenticating: true
};

const authentication = (state = initialState, action) => {
  const user = action.payload;

  switch (action.type) {
    case actionTypes.signingIn: {
      return { ...state, isAuthenticating: true };
    }
    case actionTypes.signIn: {
      return { ...state, isLoggedIn: true, user, isAuthenticating: false };
    }
    case actionTypes.signOut: {
      return { ...state, isLoggedIn: false, user: {}, isAuthenticating: false };
    }
    default:
      return state;
  }
};

export default authentication;
