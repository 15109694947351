require('dotenv').config({ path: '../../' });

export default {
  stripePublicKey: process.env.REACT_APP_stripePublicKey,
  facebookAppId: process.env.REACT_APP_facebookAppId,
  googleClientId: process.env.REACT_APP_googleClientId,
  apiUrl: process.env.REACT_APP_apiUrl,
  isDevEnvironment: process.env.NODE_ENV === 'development',
  imagesUrl: '/images',
  workHoursInDay: [
    { key: 9, displayValue: '9:00 A.M.' },
    { key: 10, displayValue: '10:00 A.M.' },
    { key: 11, displayValue: '11:00 A.M.' },
    { key: 12, displayValue: '12:00 P.M.' },
    { key: 13, displayValue: '1:00 P.M.' },
    { key: 14, displayValue: '2:00 P.M.' },
    { key: 15, displayValue: '3:00 P.M.' },
    { key: 16, displayValue: '4:00 P.M.' },
    { key: 17, displayValue: '5:00 P.M.' },
    { key: 18, displayValue: '6:00 P.M.' },
    { key: 19, displayValue: '7:00 P.M.' },
    { key: 20, displayValue: '8:00 P.M.' },
    { key: 21, displayValue: '9:00 P.M.' }
  ],
  bookingSteps: {
    pickTreatment: 'pick treatment',
    pickTherapist: 'pick therapist',
    pickMultipleTherapists: 'pick many therapists',
    clientInfo: 'client info',
    clientInfoDisplayErrors: 'client info display errors',
    payment: 'payment',
    thankYou: 'thank you'
  },
  jwtCookieKey: 'jwt',
  minHoursNoticeForTherapist: 2,
  defaultAvatarUrl: '/images/default-avatar.png',

  // payment reasons has to be synchronized between the front-end and the back-end
  paymentReasons: {
    buyGift: 'buy gift',
    redeemGift: 'redeem gift'
  },
  dateFormat: {
    short: 'MM/dd/yyyy',
    long: 'MMM dd, yyyy'
  },
  statusCodes: {
    unauthorized: 401
  },
  pages: {
    login: '/login',
    dashboard: '/dashboard',
    home: '/',
    bookAppointment: '/bookAppointment',
    giftnow: '/gifts'
  }
};
