import React from 'react';
import SignOut from './SignOut';
import SignIn from './SignIn';
import { useSelector } from 'react-redux';

const Authentication = () => {
  const { isLoggedIn, isAuthenticating } = useSelector(state => state.authentication);
  if (isAuthenticating) return <div />;
  return isLoggedIn ? <SignOut /> : <SignIn />;
};

export default Authentication;
