import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import constants from '../constants';
import Button from './Button';
import useStyles from './SignIn.css';

const SignIn = () => {
  const [redirect, setRedirect] = useState(false);
  const classes = useStyles();

  if (redirect) return <Redirect to={constants.pages.login} />;
  return <Button variant="text" className={classes.loginButton} onClick={() => setRedirect(true)} text="Log In" />;
};

export default SignIn;
