import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Link from './Link';
import { PageTitle } from '../lib/pageTitles';

const useStyles = makeStyles(() => ({
  joinOurTeam: {
    color: '#767676',
    display: 'flex',
    alignItems: 'center'
  }
}));

export default () => {
  const classes = useStyles();
  PageTitle('Join Our Team - Ulo Mobile Spa');

  return (
    <Link className={classes.joinOurTeam} destination="/becomeatherapist">
      <Typography>Join Our Team</Typography>
    </Link>
  );
};
