import React, { useState, useEffect, useRef } from 'react';
import globalStyles from './globalStyles.css.js';
import { makeStyles } from '@material-ui/core/styles';
import MaterialButton from '@material-ui/core/Button';
import ellipsis from '../images/ellipsis.svg';

const useStyles = makeStyles(theme => {
  const componentStyles = {
    button: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      maxWidth: '100%'
    },
    linkButton: {
      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
    disabled: {
      opacity: 0.5,
      cursor: 'not-allowed'
    },
    disabledText: {
      visibility: 'hidden'
    },
    icon: {
      maxWidth: theme.spacing(2),
      position: 'absolute',
      alignSelf: 'flex-end'
    }
  };

  return { ...globalStyles(theme), ...componentStyles };
});

const Button = ({ text, onClick, className: classNameOverrides, variant }) => {
  const classes = useStyles();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const isMounted = useRef(true);

  const initIsMounted = () => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  };
  useEffect(initIsMounted);

  const classNames = {
    button: [
      classes.button,
      classNameOverrides,
      buttonDisabled && classes.disabled,
      variant === 'text' && classes.linkButton
    ].join(' '),
    disabledText: buttonDisabled ? classes.disabledText : ''
  };

  const buttonClicked = async () => {
    if (buttonDisabled || !onClick) return;

    setButtonDisabled(true);
    await onClick();
    isMounted.current && setButtonDisabled(false);
  };

  return (
    <MaterialButton
      variant={variant || 'contained'}
      color={variant === 'text' ? 'default' : 'primary'}
      onClick={buttonClicked}
      className={classNames.button}
    >
      <span className={classNames.disabledText}>{text}</span>
      {buttonDisabled && <img src={ellipsis} alt="ellipsis" className={classes.icon} />}
    </MaterialButton>
  );
};

export default Button;
