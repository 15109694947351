import actionTypes from '../actionTypes';
import { startOfDay } from 'date-fns';
import constants from '../../constants';

const initialState = {
  cities: [],
  treatments: [],
  treatment: { durations: [] },
  currentStep: constants.bookingSteps.pickTreatment,
  therapists: [],
  selectedTherapists: [],
  date: startOfDay(new Date()),
  giftCode: '',
  client: {
    firstName: '',
    lastName: '',
    street: '',
    postalCode: '',
    emailAddress: '',
    phoneNumber: '',
    specialInstructions: '',
    buildingType: '',
    source: ''
  }
};

const bookAppointment = (state = initialState, action) => {
  const queryParams = window.location.search;

  switch (action.type) {
    case actionTypes.cities: {
      const newState = { ...state, cities: action.payload };
      const match = queryParams && queryParams.match('cityId=([0-9]*)');
      if (!match) return newState;

      const cityId = parseInt(match[1]);
      const city = action.payload.find(x => x.id === cityId);
      return { ...newState, city };
    }

    case actionTypes.updateCity: {
      const client = { ...state.client, city: action.payload.name, province: 'AB' };
      return { ...state, city: action.payload, client, treatment: initialState.treatment, duration: null };
    }

    case actionTypes.treatments: {
      return { ...state, treatments: action.payload };
    }

    case actionTypes.updateTreatment: {
      const treatment = action.payload;
      const previousDuration = state.duration;
      let duration = previousDuration;

      if (previousDuration) duration = treatment.durations.find(x => x.id === previousDuration.id);

      return { ...state, treatment, duration };
    }

    case actionTypes.updateDuration: {
      return { ...state, duration: action.payload };
    }

    case actionTypes.updateScreen: {
      return { ...state, currentStep: action.payload };
    }

    case actionTypes.therapists: {
      const newState = { ...state, therapists: action.payload };
      const match = queryParams && queryParams.match('therapistId=([0-9]*)');
      if (!match) return newState;

      const therapistId = parseInt(match[1]);
      const therapist = action.payload.find(x => x.id === therapistId);
      return { ...newState, therapist };
    }

    case actionTypes.updateTherapist: {
      return { ...state, selectedTherapists: action.payload };
    }

    case actionTypes.setGiftCode: {
      return { ...state, giftCode: action.payload };
    }

    case actionTypes.updateDate: {
      return { ...state, date: action.payload };
    }

    case actionTypes.updateTime: {
      return { ...state, time: action.payload };
    }

    case actionTypes.initBookAppointment: {
      const client = { ...state.client };
      return { ...initialState, client };
    }

    case actionTypes.updateClientInfo: {
      const { key, value } = action.payload;
      const client = { ...state.client, [key]: value };
      return { ...state, client };
    }

    case actionTypes.signIn: {
      const { firstName, lastName, email: emailAddress } = action.payload;
      const client = {
        ...state.client,
        firstName,
        lastName,
        emailAddress
      };
      return { ...state, client };
    }

    default:
      return state;
  }
};

export default bookAppointment;
