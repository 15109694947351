import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const generateStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    column: { flexDirection: 'column' },
    grow: { flexGrow: 1 },
    nowrap: { flexWrap: 'unset' },
    justifyCenter: { justifyContent: 'center' },
    alignCenter: { alignItems: 'center' }
  };
});

export default props => {
  const { children, className: classesFromProps, column, alignCenter, justifyCenter, grow, nowrap, ...other } = props;
  return (
    <div className={generateClassName(props)} {...other}>
      {children}
    </div>
  );
};

const generateClassName = props => {
  const classes = generateStyles();
  const { className: classesFromProps, column, justifyCenter, alignCenter, grow, nowrap } = props;

  const className = [
    classes.container,
    column && classes.column,
    grow && classes.grow,
    nowrap && classes.nowrap,
    grow && classes.grow,
    justifyCenter && classes.justifyCenter,
    alignCenter && classes.alignCenter,
    classesFromProps
  ];

  return className.filter(x => x).join(' ');
};
