import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import constants from '../constants';

const useStyles = makeStyles(theme => ({
  avatar: {
    borderRadius: '50%',
    width: '40px'
  },
  avatarContainer: {
    cursor: 'pointer'
  }
}));

const Avatar = () => {
  const { user } = useSelector(state => state.authentication);
  const classes = useStyles();

  return (
    <div className={classes.avatarContainer}>
      <img src={user.avatarUrl || constants.defaultAvatarUrl} alt="profile" className={classes.avatar} />
    </div>
  );
};

export default Avatar;
