import actionTypes from './actionTypes';

export const bookAppointment = {
  cities: { type: actionTypes.cities },
  initBookAppointment: { type: actionTypes.initBookAppointment },
  therapists: { type: actionTypes.therapists },
  treatments: { type: actionTypes.treatments },
  updateCity: { type: actionTypes.updateCity },
  updateDate: { type: actionTypes.updateDate },
  updateDuration: { type: actionTypes.updateDuration },
  updateScreen: { type: actionTypes.updateScreen },
  updateTherapist: { type: actionTypes.updateTherapist },
  updateTime: { type: actionTypes.updateTime },
  updateTreatment: { type: actionTypes.updateTreatment },
  updateClientInfo: { type: actionTypes.updateClientInfo },
  setGiftCode: { type: actionTypes.setGiftCode }
};

export const global = {
  setTherapists: { type: actionTypes.setTherapists }
};

export const therapistDashboard = {
  setTherapist: { type: actionTypes.setTherapist },
  dateChanged: { type: actionTypes.dateChanged },
  updateDefaultAvailability: { type: actionTypes.updateDefaultAvailability },
  updateDailyAvailability: { type: actionTypes.updateDailyAvailability }
};

export const authentication = {
  signingIn: { type: actionTypes.signingIn },
  signIn: { type: actionTypes.signIn },
  signOut: { type: actionTypes.signOut }
};

export const mobileHeader = {
  toggleMenu: { type: actionTypes.toggleMenu },
  hideMenu: { type: actionTypes.hideMenu }
};
