import React, { Suspense } from 'react';
import { Route, withRouter, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { mobileHeader as actions } from '../redux/actions';
import LoadingSpinner from './LoadingSpinner';

const Routes = ({ history }) => {
  const dispatch = useDispatch();
  history.listen(() => dispatch({ ...actions.hideMenu }));

  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        {/*<Route path="/" component={React.lazy(() => import('./ComingBackSoon'))} />*/}
        <Route path="/" exact component={React.lazy(() => import('./Home'))} />
        <Route path="/aboutUs" component={React.lazy(() => import('./AboutUs'))} />
        <Route path="/admin" component={React.lazy(() => import('./Admin'))} />
        <Route path="/auth/:provider/redirect" component={React.lazy(() => import('./OAuthRedirect'))} />
        <Route path="/becomeatherapist" component={React.lazy(() => import('./BecomeATherapist'))} />
        <Route path="/blogs" component={React.lazy(() => import('./Blogs'))} />
        <Route path="/bookAppointment" component={React.lazy(() => import('./BookAppointment/BookAppointment'))} />
        <Route path="/cancellationPolicy" component={React.lazy(() => import('./CancellationPolicy'))} />
        <Route path="/dashboard" component={React.lazy(() => import('./TherapistDashboard'))} />
        <Route path="/error" component={React.lazy(() => import('./Error'))} />
        <Route path="/faq" component={React.lazy(() => import('./Faq'))} />
        <Route path="/gifts" component={React.lazy(() => import('./Gifts'))} />
        <Route path="/login" component={React.lazy(() => import('./Login'))} />
        <Route path="/pricing" component={React.lazy(() => import('./Pricing'))} />
        <Route path="/privacyPolicy" component={React.lazy(() => import('./PrivacyPolicy'))} />
        <Route path="/termsConditions" component={React.lazy(() => import('./TermsConditions'))} />
        <Route path="/therapists" component={React.lazy(() => import('./Therapists'))} />
        <Route path="/treatments" component={React.lazy(() => import('./Treatments'))} />
        <Route
          path="/everything-you-need-to-know-about-deep-tissue-massage"
          component={React.lazy(() => import('./everything-you-need-to-know-about-deep-tissue-massage'))}
        />
        <Route
          path="/5-benefits-of-an-in-home-massage"
          component={React.lazy(() => import('./5-benefits-of-an-in-home-massage'))}
        />
        <Route
          path="/benefits-of-receiving-in-home-massage"
          component={React.lazy(() => import('./benefits-of-receiving-in-home-massage'))}
        />
        <Route
          path="/different-types-of-massage-and-their-benefits"
          component={React.lazy(() => import('./different-types-of-massage-and-their-benefits'))}
        />
        <Route
          path="/massage-in-home-vs-massage-at-center"
          component={React.lazy(() => import('./massage-in-home-vs-massage-at-center'))}
        />
        <Route
          path="/how-massage-helps-in-stress-release"
          component={React.lazy(() => import('./how-massage-helps-in-stress-release'))}
        />
        <Route
          path="/full-body-massage-on-demand"
          component={React.lazy(() => import('./full-body-massage-on-demand'))}
        />
        <Route path="/on-demand-massage-at-home" component={React.lazy(() => import('./on-demand-massage-at-home'))} />
        <Route
          path="/massage-services-at-your-door-step"
          component={React.lazy(() => import('./massage-services-at-your-door-step'))}
        />
        <Route
          path="/role-of-massage-in-maintaining-health"
          component={React.lazy(() => import('./role-of-massage-in-maintaining-health'))}
        />
        <Route
          path="/why-you-often-need-massage-therapy"
          component={React.lazy(() => import('./why-you-often-need-massage-therapy'))}
        />
      </Switch>
    </Suspense>
  );
};

export default withRouter(Routes);

const Loading = () => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <LoadingSpinner />
  </div>
);
