import React from 'react';
import Loader from 'react-loader-spinner';
import Flex from './Flex';

export default props => {
  return (
    <Flex className={props.className}>
      <Loader type="Rings" color="rgba(253, 209, 63, 1)" height={100} width={100} />
    </Flex>
  );
};
