import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer';
import { Provider } from 'react-redux';
import store from './redux/store';
import { authenticate } from './services/authentication';
import { theme } from './App.css.js';
import Routes from './components/Routes';
import { ReactQueryCacheProvider, QueryCache } from 'react-query';
import Flex from './components/Flex';
import { makeStyles } from '@material-ui/core/styles';

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const useStyles = makeStyles(() => {
  return {
    container: {
      maxWidth: 1600,
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto'
    }
  };
});

const AppRouter = () => {
  const classes = useStyles();
  authenticate();

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <ReactQueryCacheProvider queryCache={queryCache}>
          <Provider store={store}>
            <Header />
            <Flex className={classes.container}>
              <Routes />
            </Flex>
            <Footer />
          </Provider>
        </ReactQueryCacheProvider>
      </ThemeProvider>
    </Router>
  );
};

export default AppRouter;
