const cities = 'cities';
const dateChanged = 'dateChanged';
const hideMenu = 'hideMenu';
const initBookAppointment = 'initBookAppointment';
const resetBookingFilters = 'resetBookingFilters';
const setGiftCode = 'setGiftCode';
const setTherapist = 'setTherapist';
const setTherapists = 'setTherapists';
const signIn = 'signIn';
const signingIn = 'signingIn';
const signOut = 'signOut';
const therapists = 'therapists';
const toggleMenu = 'toggleMenu';
const treatments = 'treatments';
const updateDefaultAvailability = 'updateDefaultAvailability';
const updateDailyAvailability = 'updateDailyAvailability';
const updateCity = 'updateCity';
const updateClientInfo = 'updateClientInfo';
const updateDate = 'updateDate';
const updateDuration = 'updateDuration';
const updateScreen = 'updateScreen';
const updateTherapist = 'updateTherapist';
const updateTime = 'updateTime';
const updateTreatment = 'updateTreatment';

export default {
  cities,
  dateChanged,
  hideMenu,
  initBookAppointment,
  resetBookingFilters,
  setGiftCode,
  setTherapist,
  setTherapists,
  signIn,
  signingIn,
  signOut,
  therapists,
  toggleMenu,
  treatments,
  updateDefaultAvailability,
  updateDailyAvailability,
  updateCity,
  updateClientInfo,
  updateDate,
  updateDuration,
  updateScreen,
  updateTherapist,
  updateTime,
  updateTreatment
};
