import actionTypes from '../actionTypes';

const initialState = {};

const therapistDashboard = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.setTherapist: {
      return { ...state, therapist: action.payload };
    }
    case actionTypes.updateTherapist: {
      return { ...state, therapist: action.payload };
    }
    case actionTypes.dateChanged: {
      return { ...state, date: action.payload };
    }
    case actionTypes.updateDefaultAvailability: {
      const { therapist, date } = state;
      therapist.defaultAvailability = action.payload;
      return { date, therapist: { ...therapist } };
    }
    case actionTypes.updateDailyAvailability: {
      const { therapist, date } = state;
      therapist.dailyAvailability = { ...therapist.dailyAvailability, ...action.payload };
      return { date, therapist: { ...therapist } };
    }
    default:
      return state;
  }
};

export default therapistDashboard;
