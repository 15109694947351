import React from 'react';
import Link from '../Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => {
  const mobileView = theme.breakpoints.down('sm');

  return {
    navigationLink: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [mobileView]: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
      }
    },
    container: {
      justifyContent: 'center',
      display: 'flex'
    },
    column: {
      flexDirection: 'column'
    }
  };
});

const CenteredHeader = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const navigationLinks = [
    { displayName: 'Home', visible: isMobileView, destination: '/' },
    { displayName: 'About Us', visible: true, destination: '/aboutUs' },
    { displayName: 'Therapists', visible: true, destination: '/therapists' },
    { displayName: 'Treatments', visible: true, destination: '/treatments' },
    { displayName: 'Pricing', visible: true, destination: '/pricing' },
    { displayName: 'Gifts', visible: true, destination: '/gifts' },
    { displayName: 'Book Now', visible: true, destination: '/bookAppointment' },
    { displayName: 'Our Blogs', visible: true, destination: '/blogs' }
  ];

  const DesktopView = () => (
    <div className={classes.container}>
      {navigationLinks
        .filter(x => x.visible)
        .map(({ destination, displayName }, index) => (
          <Link className={classes.navigationLink} destination={destination} key={index}>
            <Typography>{displayName}</Typography>
          </Link>
        ))}
    </div>
  );

  const MobileView = () => (
    <div className={`${classes.container} ${classes.column}`}>
      {navigationLinks
        .filter(x => x.visible)
        .map(({ destination, displayName }, index) => (
          <Link className={classes.navigationLink} destination={destination} key={index}>
            <Typography>{displayName}</Typography>
          </Link>
        ))}
    </div>
  );

  return isMobileView ? <MobileView /> : <DesktopView />;
};

export default CenteredHeader;
