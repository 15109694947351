import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Flex from '../Flex';
import { makeStyles } from '@material-ui/core/styles';
import CenteredHeader from './CenteredHeader';
import RightHeader from './RightHeader';
import { useDispatch, useSelector } from 'react-redux';
import { mobileHeader as actions } from '../../redux/actions';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  header: {
    justifyContent: 'space-between'
  },
  links: {
    marginBottom: theme.spacing(5)
  }
}));

const MobileHeader = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const displayMenu = useSelector(state => state.mobileHeader.displayMenu);

  return (
    <Flex className={classes.container} column grow>
      <Flex className={classes.header}>
        <IconButton onClick={() => dispatch({ ...actions.toggleMenu })}>
          <MenuIcon />
        </IconButton>
        <RightHeader />
      </Flex>
      {displayMenu && (
        <Flex column className={classes.links}>
          <CenteredHeader />
        </Flex>
      )}
    </Flex>
  );
};

export default MobileHeader;
