export default theme => {
  const mobileView = theme.breakpoints.down('sm');
  const desktopView = theme.breakpoints.up('sm');
  const margin = theme.spacing(0.5);

  return {
    heroImage: {
      width: '100%',
      height: '50vh'
    },
    heading: {
      alignItems: 'center',
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2)
    },
    container: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    inputContainer: {
      marginLeft: -margin
    },
    input: {
      backgroundColor: theme.palette.common.white,
      margin: theme.spacing(0.5),
      [mobileView]: {
        marginLeft: 0,
        marginRight: 0
      }
    },
    leftControl: {
      [desktopView]: { marginRight: theme.spacing(1) }
    },
    rightControl: {
      [desktopView]: { marginLeft: theme.spacing(1) }
    },
    oneColumn: {
      flexBasis: '33.3%'
    },
    twoColumn: {
      flexBasis: '66.6%'
    },
    section: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      maxWidth: theme.spacing(100),
      [desktopView]: {
        width: '75%',
        justifyContent: 'center'
      },
      [mobileView]: {
        width: '100%'
      }
    },
    stairsOptions: {
      paddingLeft: theme.spacing(4)
    },
    choiceContent: {
      justifyContent: 'center'
    },
    avatarChoiceContainer: {
      [mobileView]: {
        width: 'unset'
      }
    },
    avatarChoiceContent: {
      padding: theme.spacing(1)
    },
    avatarContainer: {
      width: theme.spacing(15),
      height: theme.spacing(15),
      marginBottom: theme.spacing(1)
    },
    avatar: {
      borderRadius: '50%'
    },
    icon: {
      maxWidth: theme.spacing(2)
    },
    trimRight: {
      [desktopView]: {
        marginRight: theme.spacing(1)
      }
    },
    picker: {
      [desktopView]: { width: 'fit-content' }
    },
    error: {
      color: theme.palette.error.main
    }
  };
};
