import React from 'react';
import Flex from './Flex';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { ExternalLink } from './Link';
import { withRouter } from 'react-router';

const useStyles = makeStyles(theme => {
  const mobileView = `@media (max-width: ${theme.breakpoints.values.sm}px)`;
  const desktopView = `@media (min-width: ${theme.breakpoints.values.sm}px)`;

  return {
    container: {
      borderTopStyle: 'solid',
      borderTopWidth: 1,
      borderTopColor: theme.palette.grey[200],
      paddingTop: theme.spacing(5),
      backgroundColor: theme.palette.common.white,
      [desktopView]: {
        paddingBottom: theme.spacing(20)
      },
      [mobileView]: {
        justifyContent: 'center'
      }
    },
    section: {
      width: 'unset',
      [desktopView]: {
        marginLeft: theme.spacing(5),
        marginRight: theme.spacing(5)
      },
      [mobileView]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginBottom: theme.spacing(5),
        alignItems: 'center'
      }
    },
    heading: {
      paddingBottom: theme.spacing(2)
    },
    link: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    }
  };
});

const Heading = props => {
  const classes = useStyles();
  const { children } = props;

  return (
    <Typography variant="subtitle1" className={classes.heading}>
      {children}
    </Typography>
  );
};

const Footer = props => {
  const classes = useStyles();

  const { pathname: currentRoute } = props.location;
  return (
    currentRoute !== '/bookAppointment' && (
      <Flex className={classes.container} justifyCenter>
        <Flex column className={classes.section}>
          <Heading>Company</Heading>
          <ExternalLink destination="/aboutUs" className={classes.link}>
            <Typography>About Us</Typography>
          </ExternalLink>
          <ExternalLink className={classes.link}>
            <Typography>Contact</Typography>
          </ExternalLink>
          <ExternalLink destination="/faq" className={classes.link}>
            <Typography>FAQ</Typography>
          </ExternalLink>
        </Flex>
        <Flex column className={classes.section}>
          <Heading>Careers</Heading>
          <ExternalLink destination="/becomeatherapist" className={classes.link}>
            <Typography>Join Our Team</Typography>
          </ExternalLink>
        </Flex>
        <Flex column className={classes.section}>
          <Heading>Social</Heading>
          <ExternalLink destination="//www.facebook.com/ulomobilespa" className={classes.link}>
            <Typography>Facebook</Typography>
          </ExternalLink>
          <ExternalLink destination="//www.twitter.com/ulocanada" className={classes.link}>
            <Typography>Twitter</Typography>
          </ExternalLink>
          <ExternalLink destination="//www.instagram.com/ulomobilespa" className={classes.link}>
            <Typography>Instagram</Typography>
          </ExternalLink>
        </Flex>
        <Flex column className={classes.section}>
          <Heading>Legal</Heading>
          <ExternalLink destination="/termsConditions" className={classes.link}>
            <Typography>Terms &amp; Conditions</Typography>
          </ExternalLink>
          <ExternalLink destination="/privacyPolicy" className={classes.link}>
            <Typography>Privacy Policy</Typography>
          </ExternalLink>
          <ExternalLink destination="/cancellationPolicy" className={classes.link}>
            <Typography>Cancellation Policy</Typography>
          </ExternalLink>
        </Flex>
      </Flex>
    )
  );
};

export default withRouter(Footer);
