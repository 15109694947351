import actionTypes from '../actionTypes';

const initialState = {
  therapists: [ ]
}

const global = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.setTherapists: {
      return { ...state, therapists: action.payload }
    }
    default:
      return state;
  }
}

export default global;
