import React from 'react';
import Flex from '../Flex';
import Link from '../Link';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../../images/logo.png';

const useStyles = makeStyles(theme => ({
  image: {
    width: theme.spacing(7.5),
    height: theme.spacing(5)
  },
  container: {
    width: 'unset'
  }
}));

const LeftHeader = () => {
  const classes = useStyles();
  return (
    <Flex className={classes.container}>
      <Link destination="/">
        <img src={logo} alt="Logo" className={classes.image} />
      </Link>
    </Flex>
  );
};

export default LeftHeader;
