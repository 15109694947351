import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import ButtonBase from '@material-ui/core/ButtonBase';

const useStyles = makeStyles(theme => ({
  plain: {
    textDecoration: 'none',
    color: 'inherit',
    cursor: 'pointer'
  }
}));

const CustomLink = props => {
  const classes = useStyles();
  const { children, className: customStyles, destination, onClick } = props;

  const buttonLink = (
    <ButtonBase onClick={onClick} className={customStyles}>
      {children}
    </ButtonBase>
  );

  const anchorLink = (
    <Link className={`${classes.plain} ${customStyles || ''}`} to={destination || '/'}>
      {children}
    </Link>
  );

  return onClick ? buttonLink : anchorLink;
};

const ExternalLink = ({ destination, children, className: customStyles }) => {
  const classes = useStyles();
  return (
    <a className={`${classes.plain} ${customStyles || ''}`} href={destination}>
      {children}
    </a>
  );
};

export { ExternalLink };
export default CustomLink;
