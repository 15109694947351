import actionTypes from '../actionTypes';

const initialState = {
  displayMenu: false
};

export default (state = initialState, action) => {
  const { displayMenu } = state;

  switch (action.type) {
    case actionTypes.toggleMenu: {
      return { ...state, displayMenu: !displayMenu };
    }
    case actionTypes.hideMenu: {
      return { ...state, displayMenu: false };
    }
    default:
      return state;
  }
};
