import { validateAuth, logout } from '../lib/request';
import store from '../redux/store';
import { authentication as actions } from '../redux/actions';

const { dispatch } = store;

const authenticate = async () => {
  dispatch({ ...actions.signingIn });
  const response = await validateAuth();
  if (response.success && response.data?.user) dispatch({ ...actions.signIn, payload: response.data.user });
  else dispatch({ ...actions.signOut });
};

const signOut = async () => {
  await logout();
  dispatch({ ...actions.signOut });
};

export { authenticate, signOut };
