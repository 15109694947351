import React, { Fragment, useState } from 'react';
import Link from './Link';
import { signOut } from '../services/authentication';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Avatar from './Avatar';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import Flex from './Flex';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  menu: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    width: 'unset',
    alignItems: 'flex-end'
  },
  menuItem: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
}));

const SignOut = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const closeMenu = () => setAnchorEl(null);
  const classes = useStyles();
  const { user } = useSelector(state => state.authentication);

  const logOut = async () => {
    closeMenu();
    await signOut();
    props.history.push('/');
  };

  const gotoDashboard = () => {
    closeMenu();
    props.history.push('/dashboard');
  };

  const gotoAdmin = () => {
    closeMenu();
    props.history.push('/admin');
  };

  return (
    <Fragment>
      <div onClick={event => setAnchorEl(event.currentTarget)}>
        <Avatar />
      </div>
      <Popover
        id="profile-menu"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Flex className={classes.menu} column>
          <Link onClick={logOut} className={classes.menuItem}>
            <Typography>Log Out</Typography>
          </Link>
          {user.therapistId && (
            <Link onClick={gotoDashboard} className={classes.menuItem}>
              <Typography>My Dashboard</Typography>
            </Link>
          )}
          {user.isAdmin && (
            <Link onClick={gotoAdmin} className={classes.menuItem}>
              <Typography>Admin</Typography>
            </Link>
          )}
        </Flex>
      </Popover>
    </Fragment>
  );
};

export default withRouter(SignOut);
