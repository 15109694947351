import axios from 'axios';
import constants from '../constants.js';

export const stripePayment = async (token, paymentData) =>
  post(`${constants.apiUrl}/stripePayment`, { token, paymentData });
export const processPayment = async paymentData => post(`${constants.apiUrl}/processPayment`, paymentData);
export const getCities = async () => await get(`${constants.apiUrl}/cities`);
export const getTreatmentsInCity = async city => await get(`${constants.apiUrl}/cities/${city.id}/treatments`);
export const getAllTreatments = async () => await get(`${constants.apiUrl}/treatments`);
export const getAllTherapists = async () => await get(`${constants.apiUrl}/therapists`);
export const getTherapists = async treatment => await get(`${constants.apiUrl}/treatments/${treatment.id}/therapists`);
export const getTherapist = async () => await get(`${constants.apiUrl}/therapists/me`);
export const getDiscount = async discountCode => await get(`${constants.apiUrl}/discounts/${discountCode}`);
export const getRecentAppointments = async () => await get(`${constants.apiUrl}/auth/recentAppointments`);
export const getGiftTypes = async () => await get(`${constants.apiUrl}/giftTypes`);
export const getAppointments = async therapistId =>
  await get(`${constants.apiUrl}/admin/therapists/${therapistId}/appointments/upcoming`);
export const updateAppointment = async appointment =>
  await post(`${constants.apiUrl}/auth/appointments(${appointment._id})/update`, appointment);
export const validateAuth = async () => await get(`${constants.apiUrl}/auth/validate`);
export const authenticate = async (provider, queryParams) =>
  await get(`${constants.apiUrl}/auth/${provider}/redirect${queryParams}`);
export const updateTherapistAvailability = async availability =>
  await post(`${constants.apiUrl}/therapists/me/availability`, availability);
export const updateTherapist = async therapist => await put(`${constants.apiUrl}/admin/therapists`, { therapist });
export const submitApplication = async info => await post(`${constants.apiUrl}/submitApplication`, info);
export const uploadFiles = async formdata => await post(`${constants.apiUrl}/uploadFiles`, formdata);
export const signUp = async (user, password) => await post(`${constants.apiUrl}/auth/signUp`, { user, password });
const login = async creds => await post(`${constants.apiUrl}/auth/local`, creds);
export const logout = async () => await get(`${constants.apiUrl}/auth/logout`);
export const giftCodeDetails = async giftCode => await get(`${constants.apiUrl}/giftCode/${giftCode}`);
export const updateUser = async user => await put(`${constants.apiUrl}/admin/users`, { user });
export const saveTimeEntry = async timeEntry => post(`${constants.apiUrl}/therapists/timeEntry`, timeEntry);
export const deleteTimeEntry = async timeEntry => del(`${constants.apiUrl}/therapists/timeEntry`, timeEntry);

const get = async (url, queryParams = {}) => {
  await waitOneSecond();
  const opts = {
    params: queryParams,
    withCredentials: true
  };

  return axios
    .get(url, opts)
    .then(response => ({ ...response, success: true }))
    .catch(error => ({ ...error.response, success: false }));
};

const post = async (url, data) => {
  await waitOneSecond();
  const opts = { withCredentials: true };

  return await axios
    .post(url, data, opts)
    .then(response => ({ ...response, success: true }))
    .catch(error => ({ ...error.response, success: false }));
};

const put = async (url, data) => {
  await waitOneSecond();
  const opts = { withCredentials: true };

  return await axios
    .put(url, data, opts)
    .then(response => ({ ...response, success: true }))
    .catch(error => ({ ...error.response, success: false }));
};

const del = async (url, data) => {
  await waitOneSecond();
  const opts = { data, withCredentials: true };

  return await axios
    .delete(url, opts)
    .then(response => ({ ...response, success: true }))
    .catch(error => ({ ...error.response, success: false }));
};

const waitOneSecond = () => {
  if (!constants.isDevEnvironment) return;

  console.log('mimicing one second network latency');
  return new Promise(resolve => setTimeout(resolve, 1000));
};

export default {
  signUp,
  login
};
