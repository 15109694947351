import CenteredHeader from './CenteredHeader';
import Flex from '../Flex';
import LeftHeader from './LeftHeader';
import MobileHeader from './MobileHeader';
import React, { Fragment } from 'react';
import RightHeader from './RightHeader';
import Toolbar from '@material-ui/core/Toolbar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  navigationContainer: {
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  container: {
    display: 'flex',
    backgroundColor: theme.palette.common.white
  }
}));

const Header = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Toolbar className={classes.container}>
      <Flex className={classes.navigationContainer} nowrap>
        {isMobileView && <MobileHeader />}
        {!isMobileView && (
          <Fragment>
            <LeftHeader />
            <CenteredHeader />
            <RightHeader />
          </Fragment>
        )}
      </Flex>
    </Toolbar>
  );
};

export default Header;
